import * as React from "react"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import Menu from "@mui/material/Menu"
import MenuIcon from "@mui/icons-material/Menu"
import Container from "@mui/material/Container"
import Button from "@mui/material/Button"
import MenuItem from "@mui/material/MenuItem"
import { Link } from "react-router-dom"
import { Link as LinkBtn } from "@mui/material"

import logo from "../assets/fsw-logo2.png"
// import { useSelector } from "react-redux"
// import { selectLoggedInUser } from "../rtkStore/authSlice"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
// import ChevronRightIcon from "@mui/icons-material/ChevronRight"

const Header = ({ history }) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [anchorE2, setAnchorE2] = React.useState(null)
  const [anchorE3, setAnchorE3] = React.useState(null)

  const openAbout = Boolean(anchorEl)
  const openStudy = Boolean(anchorE2)
  const openCities = Boolean(anchorE3)

  const handleClickAbout = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseAbout = () => {
    setAnchorEl(null)
    handleCloseNavMenu()
  }

  const handleClickStudy = (event) => {
    setAnchorE2(event.currentTarget)
  }
  const handleCloseStudy = () => {
    setAnchorE2(null)
  }

  const handleClickCities = (event) => {
    setAnchorE3(event.currentTarget)
  }
  const handleCloseCities = () => {
    setAnchorE3(null)
    setAnchorE2(null)
    handleCloseNavMenu()
  }

  // const loggedInUser = useSelector(selectLoggedInUser)

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  return (
    <AppBar position='static' color='primary' className='navHeader'>
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          <Typography
            variant='h6'
            noWrap
            component='div'
            sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
          >
            <Link to='/'>
              <img className='logoimage' src={logo} alt='logiImage'></img>
            </Link>
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='warning'
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounte
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign='center'>
                  <Link to='/' className='NavMenu'>
                    Home
                  </Link>
                </Typography>
              </MenuItem>

              <MenuItem>
                <Typography textAlign='center'>
                  <Button className='NavMenu' onClick={handleClickAbout}>
                    About Us <ArrowDropDownIcon />
                  </Button>
                  <Menu
                    id='about-menu'
                    anchorEl={anchorEl}
                    open={openAbout}
                    onClose={handleCloseAbout}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={handleCloseAbout}>
                      <Link to='/meet-our-team'>Meet the Team</Link>
                    </MenuItem>
                    <MenuItem onClick={handleCloseAbout}>
                      <Link to='/about'>Our Story</Link>
                    </MenuItem>
                  </Menu>
                </Typography>
              </MenuItem>
              <MenuItem>
                <Typography textAlign='center'>
                  <Button className='NavMenu' onClick={handleClickStudy}>
                    Study Abroad <ArrowDropDownIcon />
                    {/* Study in Aus <ArrowDropDownIcon /> */}
                  </Button>
                </Typography>
              </MenuItem>

              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign='center'>
                  <Link to='/locations' className='NavMenu'>
                    Locations
                  </Link>
                </Typography>
              </MenuItem>
              {/* <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign='center'>
                  <Link to='' className='HeaderNavLink'>
                    Latest Updates
                  </Link>
                </Typography>
              </MenuItem> */}
              {/* <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign='center'>
                  <Link to='/test-preaparation' className='HeaderNavLink'>
                    Test Preparation
                  </Link>
                </Typography>
              </MenuItem> */}
              {/* <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign='center'>
                  <Link to='/blog' className='HeaderNavLink'>
                    Blog
                  </Link>
                </Typography>
              </MenuItem> */}
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign='center'>
                  <Link to='/contact' className='NavMenu'>
                    Contact Us
                  </Link>
                </Typography>
              </MenuItem>

              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign='center'>
                  <a
                    href='https://fsw.firstsourceworld.com/courses'
                    rel='noreferrer'
                    target='_blank'
                    style={{
                      backgroundColor: "#eb2428",
                      color: "#fff",
                      padding: "3px",
                      fontWeight: 600,
                      borderRadius: "5px",
                    }}
                  >
                    Find Course
                  </a>
                </Typography>
              </MenuItem>
            </Menu>
          </Box>

          <Typography
            variant='h6'
            noWrap
            component='div'
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
          >
            <Link to='/'>
              <img className='logoimage' src={logo} alt='logoImage'></img>
            </Link>
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}></Box>

          <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
            <Button variant='outline' className='headerNavLink'>
              <Link to='/'>Home</Link>
            </Button>
            <Button className='NavMenu' onClick={handleClickAbout}>
              About Us <ArrowDropDownIcon />
            </Button>
            <Menu
              id='about-menu'
              anchorEl={anchorEl}
              open={openAbout}
              onClose={handleCloseAbout}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <Link to='/meet-our-team'>
                <MenuItem onClick={handleCloseAbout}> Meet the Team </MenuItem>
              </Link>

              <Link to='/about'>
                <MenuItem onClick={handleCloseAbout}> Our Story </MenuItem>
              </Link>
            </Menu>
            <Button className='NavMenu' onClick={handleClickStudy}>
              Study Abroad <ArrowDropDownIcon />
              {/* Study in Aus <ArrowDropDownIcon /> */}
            </Button>
            <Menu
              id='study-menu'
              anchorEl={anchorE2}
              open={openStudy}
              onClose={handleCloseStudy}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <a
                href='https://fsw.firstsourceworld.com/courses'
                target='_blank'
                rel='noreferrer'
              >
                <MenuItem onClick={handleCloseStudy}>Find a Course </MenuItem>
              </a>

              <MenuItem>
                <LinkBtn
                  // className='NavMenu'
                  component='button'
                  id='basic-button'
                  aria-controls={openCities ? "basic-menu" : undefined}
                  aria-haspopup='true'
                  aria-expanded={openCities ? "true" : undefined}
                  onClick={handleClickCities}
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Popular Destinations
                  <ArrowDropDownIcon />
                </LinkBtn>
                <Menu
                  id='basic-menu'
                  anchorEl={anchorE3}
                  open={openCities}
                  onClose={handleCloseCities}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <Link to={`/study-abroad/popular-destinations/Australia`}>
                    <MenuItem onClick={handleCloseCities}>Australia</MenuItem>
                  </Link>

                  <Link to={`/study-abroad/popular-destinations/Canada`}>
                    <MenuItem onClick={handleCloseCities}> Canada </MenuItem>
                  </Link>

                  <Link to={`/study-abroad/popular-destinations/UK`}>
                    <MenuItem onClick={handleCloseCities}>UK</MenuItem>
                  </Link>
                  <Link to={`/study-abroad/popular-destinations/USA`}>
                    <MenuItem onClick={handleCloseCities}>USA</MenuItem>
                  </Link>

                  {/* <MenuItem onClick={handleCloseCities}>
                    <Link to={`/study-in-aus/popular-city/${"Melbourne"}`}>
                      Melbourne
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleCloseCities}>
                    <Link to={`/study-in-aus/popular-city/${"Perth"}`}>
                      Perth
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleCloseCities}>
                    <Link to={`/study-in-aus/popular-city/${"Adelaide"}`}>
                      Adelaide
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleCloseCities}>
                    <Link to={`/study-in-aus/popular-city/${"Canberra"}`}>
                      Canberra
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleCloseCities}>
                    <Link to={`/study-in-aus/popular-city/${"Darwin"}`}>
                      Darwin
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleCloseCities}>
                    <Link to={`/study-in-aus/popular-city/${"GoldCoast"}`}>
                      Gold Coast
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleCloseCities}>
                    <Link to={`/study-in-aus/popular-city/${"Hobart"}`}>
                      Hobart
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleCloseCities}>
                    <Link to={`/study-in-aus/popular-city/${"Toronto"}`}>
                      Toronto
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleCloseCities}>
                    <Link to={`/study-in-aus/popular-city/${"Vancouver"}`}>
                      Vancouver
                    </Link>
                  </MenuItem> */}
                </Menu>
              </MenuItem>
            </Menu>

            {/* <Button variant='outline' className='headerNavLink'>
              <Link to='/study-abroad' className='HeaderNavLink'>
                Study Abroad
              </Link>
            </Button> */}
            {/* <Button variant='outline' className='headerNavLink'>
              <Link to='/test-preaparation' className='HeaderNavLink'>
                Test Preparation
              </Link>
            </Button> */}
            {/* <Button variant="outline" className="headerNavLink">
              <Link to="/blogs" className="HeaderNavLink">
                Blog
              </Link>
            </Button> */}
            {/* <Button variant='outline' className='headerNavLink'>
              <Link to='#' className='HeaderNavLink'>
                Latest Updates
              </Link>
            </Button> */}
            <Button variant='outline' className='headerNavLink'>
              <Link to='/locations' className='HeaderNavLink'>
                Locations
              </Link>
            </Button>
            <Button variant='outline' className='headerNavLink'>
              <Link to='/contact' className='HeaderNavLink'>
                Contact Us
              </Link>
            </Button>
            {/* <Button
              variant='outline'
              className='headerNavLink'
              
            >
              <a
                href='https://eduxgateway.com/public/courses'
                rel='noreferrer'
                target='_blank'
                className='rounded-3xl bg-red-700 px-8 py-1.5 font-medium text-white'
              >
                Find Course
              </a>
            </Button> */}

            <Button
              variant='contained'
              className='ContactSubmitBtn'
              style={{ marginLeft: "2rem" }}
            >
              <a
                // href='https://eduxgateway.com/public/courses'
                href='https://fsw.firstsourceworld.com/courses'
                rel='noreferrer'
                target='_blank'
              >
                Find Course
              </a>
            </Button>

            {/* <Button className='enrollButton'>
              <Link to='/enroll' className='enrollBtn'>
                Enroll
              </Link>
            </Button> */}
          </Box>

          {/* {!loggedInUser ? (
            <>            
              <div className='ml-3 rounded-3xl bg-red-700 px-8 py-1.5 font-medium text-white'>
                <a
                  activeClass='active'
                  href='https://eduxgateway.com/sign-in'
                  target='_blank'
                  rel='noreferrer'
                >
                  Sign In
                </a>
              </div>
            </>
          ) : (
            <Link
              to='/app/dashboard'
              style={{ color: "black", marginLeft: "0.5rem" }}
            >
              <div className='rounded-3xl bg-red-700 px-8 py-1.5 font-medium text-white'>
                Dashboard
              </div>
            </Link>
          )} */}
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default Header
