import { Box, Button, Container, Grid, Typography } from "@mui/material"
import React from "react"

export const Step4 = () => {
  return (
    <Box className='step4Background' sx={{ px: "2rem" }}>
      <Container maxWidth='xl' align='center' sx={{ padding: "0px" }}>
        <Typography
          gutterBottom
          variant='h5'
          component='div'
          align='center'
          mt={3}
          mb={3}
          sx={{ fontSize: 28, fontWeight: 700 }}
        >
          Make your dream a reality.
          {/* Make your Australian Dream a Reality. */}
        </Typography>
        <Grid
          container
          sx={{
            marginTop: "1rem",
            display: { xs: "none", md: "block", lg: "block" },
          }}
        >
          <Box className='CircleContainer'>
            <Grid item xs={12} lg={3}>
              <Box className='step4Box'>
                <Box className='stepIcons'>
                  <Typography align='center' className='stepText'>
                    1
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Box className='step4Box'>
                <Box className='stepIcons'>
                  <Typography align='center' className='stepText'>
                    2
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Box className='step4Box'>
                <Box className='stepIcons'>
                  <Typography align='center' className='stepText'>
                    3
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Box className='step4Box'>
                <Box className='stepIcons'>
                  <Typography align='center' className='stepText'>
                    4
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Box className='step4Box'>
                <Box className='stepIcons'>
                  <Typography align='center' className='stepText'>
                    5
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Box>

          <Box display='flex'>
            <Grid item xs={12} sm={12} md={3}>
              <Box className='step4Text'>
                <Typography sx={{ fontSize: 18, fontWeight: 800 }}>
                  Accomplish inquiry form
                </Typography>
                <Typography sx={{ fontSize: 16, fontWeight: 500, padding: 3 }}>
                  By providing your contact details, one of our qualified
                  education counsellors can reach you.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Box className='step4Text'>
                <Typography sx={{ fontSize: 18, fontWeight: 800 }}>
                  Receive counselling
                </Typography>
                <Typography sx={{ fontSize: 16, fontWeight: 500, padding: 3 }}>
                  We provide you with personalized counselling and best study
                  options.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Box className='step4Text'>
                <Typography sx={{ fontSize: 18, fontWeight: 800 }}>
                  Enroll to a college
                </Typography>
                <Typography sx={{ fontSize: 16, fontWeight: 500, padding: 3 }}>
                  We fully assist the process of obtaining COE from the college.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Box className='step4Text'>
                <Typography sx={{ fontSize: 18, fontWeight: 800 }}>
                  Apply for student visa
                </Typography>
                <Typography sx={{ fontSize: 16, fontWeight: 500, padding: 3 }}>
                  We lodge your visa and assist on booking your health exam and
                  biometrics.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Box className='step4Text'>
                <Typography sx={{ fontSize: 18, fontWeight: 800 }}>
                  Visa granted! Fly anytime
                </Typography>
                <Typography
                  sx={{
                    fontSize: 16,
                    fontWeight: 500,
                    padding: 3,
                  }}
                >
                  You can fly to the country of your destination as an
                  international student stress-free with our predeparture
                  orientation.
                </Typography>
              </Box>
            </Grid>
          </Box>
        </Grid>

        <Grid
          container
          sx={{
            marginTop: "2rem",
            display: { xs: "block", md: "none", lg: "none" },
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            display='flex'
            alignContent='center'
          >
            <Box className='stepIcons'>
              <Typography align='center' className='stepText'>
                1
              </Typography>
            </Box>
            <Box className='step4Text'>
              <Typography
                sx={{ fontSize: 15, fontWeight: 800, marginLeft: "1rem" }}
              >
                Complete inquiry form
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            display='flex'
            alignContent='center'
          >
            <Box className='stepIcons'>
              <Typography align='center' className='stepText'>
                2
              </Typography>
            </Box>
            <Box className='step4Text'>
              <Typography
                sx={{ fontSize: 15, fontWeight: 800, marginLeft: "1rem" }}
              >
                Counselling and receive study options
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            display='flex'
            alignContent='center'
          >
            <Box className='stepIcons'>
              <Typography align='center' className='stepText'>
                3
              </Typography>
            </Box>
            <Box className='step4Text'>
              <Typography
                sx={{ fontSize: 15, fontWeight: 800, marginLeft: "1rem" }}
              >
                School Application
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            display='flex'
            alignContent='center'
          >
            <Box className='stepIcons'>
              <Typography align='center' className='stepText'>
                4
              </Typography>
            </Box>
            <Box className='step4Text'>
              <Typography
                sx={{ fontSize: 15, fontWeight: 800, marginLeft: "1rem" }}
              >
                Visa Application
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            display='flex'
            alignContent='center'
          >
            <Box className='stepIcons'>
              <Typography align='center' className='stepText'>
                5
              </Typography>
            </Box>
            <Box className='step4Text'>
              <Typography
                sx={{ fontSize: 15, fontWeight: 800, marginLeft: "1rem" }}
              >
                Once granted, fly to Australia!
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Button
          variant='contained'
          sx={{ mt: 3, mb: 2, width: 200, marginTop: "3rem" }}
          className='ContactSubmitBtn'
          align='center'
        >
          <a
            // href='https://eduxgateway.com/public/courses'
            href='https://fsw.firstsourceworld.com/courses'
            rel='noreferrer'
            target='_blank'
          >
            Apply Now
          </a>
        </Button>
      </Container>
    </Box>
  )
}
