import React, { useState } from "react"
// import OurServices from "../../components/OurServices"
import Banner from "../../components/Banner"
import image from "../assets/images/australiaBannerImage.png"
import {
  Alert,
  Box,
  Button,
  Container,
  // FormControl,
  Grid,
  // InputLabel,
  // MenuItem,
  // Select,
  // TextField,
  Typography,
} from "@mui/material"
import SydneyMap from "../assets/images/sydneyMap.png"
import popularCourse1 from "../assets/images/popularCourse1.png"
import popularCourse2 from "../assets/images/popularCourse2.png"
import popularCourse3 from "../assets/images/popularCourse3.png"
import popularCourse4 from "../assets/images/popularCourse4.png"
import popularCourse5 from "../assets/images/popularCourse5.png"
import popularCourse6 from "../assets/images/popularCourse6.png"
import fswMonitor from "../assets/images/fswMonitor.png"
import ApplicationProcess from "../../components/ApplicationProcess"
import ConsultExpert from "../../components/ConsultExpert"
// import EmailField from "@mui/material/TextField"
// import axios from "axios"

const courseOptions = [
  { image: popularCourse1, title: "Construction Courses" },
  { image: popularCourse2, title: "Hospitality Courses" },
  { image: popularCourse3, title: "Engineering Courses" },
  { image: popularCourse4, title: "Aged Care Courses" },
  { image: popularCourse5, title: "IT Courses" },
  { image: popularCourse6, title: "Teaching Courses" },
]

const initialState = {
  fname: "",
  lname: "",
  email: "",
  mobile: "",
  age: "",
  nationality: "",
  currentLocation: "",
  course: "",
  preferredCity: "",
}

const StudyInAus = () => {
  const [showMessage, setShowMessage] = useState(false)

  const [state, dispatch] = React.useReducer((next, prev) => {
    return { ...next, ...prev }
  }, initialState)

  const formSubmit = async (e) => {
    e.preventDefault()
    setShowMessage(true)
    dispatch(initialState)

    return
    // axios
    //   .post(`/api/contactus-email/app/email-list/create`, {
    //     ...state,
    //   })
    //   .then((res) => setData(res.data))
  }

  return (
    <>
      <Banner image={image} title={"Study in Australia"} />
      <Container maxWidth='xl'>
        <Grid
          container
          spacing={5}
          direction='row'
          justifyContent='center'
          alignItems='center'
          className='GlobalBranchContainer'
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            data-aos='fade-right'
            data-aos-offset='200'
            data-aos-delay='50'
            data-aos-duration='2000'
          >
            <Typography
              gutterBottom
              variant='h5'
              component='div'
              mt={3}
              sx={{ fontSize: 22, fontWeight: 700 }}
            >
              Introduction to Sydney
            </Typography>

            <Typography>
              <p className='GlobalBranchesDescription'>
                Sydney is the largest and most populous city in Australia,
                located on the southeastern coast of the country.{" "}
              </p>
              <br />
              <p className='GlobalBranchesDescription'>
                It is known for its iconic landmarks, stunning harbor, vibrant
                culture, and diverse population.
              </p>
              <br />
              <p className='GlobalBranchesDescription'>
                The city boasts a stunning natural landscape, with beautiful
                sandy beaches such as Bondi, Manly, and Coogee.
              </p>
              <br />
              <p className='GlobalBranchesDescription'>
                Overall, Sydney is a city that seamlessly blends natural beauty,
                cultural richness, and a dynamic urban lifestyle. Sydney offers
                a diverse range of experiences that cater to every taste.
              </p>
              <br />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={2}></Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            data-aos='fade-left'
            data-aos-offset='200'
            data-aos-delay='50'
            data-aos-duration='2000'
          >
            <img
              src={SydneyMap}
              alt='SydneyMap'
              className='WorldMapImage'
            ></img>
          </Grid>
        </Grid>
      </Container>
      <ConsultExpert />
      <Container maxWidth='xl' sx={{ marginTop: 8 }}>
        <Typography
          gutterBottom
          variant='h5'
          component='div'
          mt={3}
          sx={{
            fontSize: 22,
            fontWeight: 700,
            textAlign: "center",
            marginBottom: 3,
          }}
        >
          Here are the Popular Courses in Sydney
        </Typography>

        <Grid
          container
          spacing={5}
          display='flex'
          justifyContent='center'
          alignItems='center'
          textAlign='center'
          className='GlobalBranchContainer'
        >
          {courseOptions.map((item) => (
            <Grid
              key={item}
              item
              xs={12}
              sm={12}
              md={4}
              data-aos='fade-left'
              data-aos-offset='200'
              data-aos-delay='50'
              data-aos-duration='2000'
            >
              <Box display={"flex"} justifyContent={"Center"}>
                <img
                  src={item.image}
                  alt='CourseImage'
                  className='WorldMapImage'
                ></img>
              </Box>

              <Typography sx={{ fontSize: 18, fontWeight: 500, my: 1 }}>
                {item.title}
              </Typography>
            </Grid>
          ))}
        </Grid>

        <ApplicationProcess />
      </Container>

      <Container>
        <Box
          sx={{
            marginTop: 2,
          }}
        >
          {showMessage && (
            <Alert severity='success'>Message send successfully.</Alert>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            boxShadow: "2px 4px 29px rgba(0, 0, 0, 0.25)",
            p: 2,
            marginBottom: 8,
            borderRadius: "10px",
          }}
        >
          <Grid
            container
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <Grid
              item
              sm={12}
              md={12}
              mt={2}
              data-aos='fade-left'
              data-aos-offset='200'
              data-aos-delay='50'
              data-aos-duration='2000'
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                  alignItems: "center",
                  pt: 2,
                  marginLeft: 2,
                  marginBottom: 3,
                }}
              >
                <img
                  src={fswMonitor}
                  alt='SydneyMap'
                  style={{ width: "40%", textAlign: "center" }}
                ></img>
                <Button
                  type='submit'
                  variant='contained'
                  sx={{ mt: 3, mb: 2, width: 200 }}
                  className='ContactSubmitBtn'
                >
                  <a
                    // href='https://eduxgateway.com/public/courses'
                    href='https://fsw.firstsourceworld.com/courses'
                    rel='noreferrer'
                    target='_blank'
                  >
                    Apply Now
                  </a>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  )
}

export default StudyInAus
